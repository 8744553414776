import L from "leaflet"
import { useMapContext } from "@/context/map-context"
import { useEffect, useRef } from "react"
import SupplyRoutesData from "@/data/supply-routes.json"
import { useTheme } from "@/context/theme-provider"
import type { Theme } from "@/context/theme-provider"

export function SupplyRouteLayer() {
  const { mapInstance, layers } = useMapContext()
  const SupplyRoutesRef = useRef<L.GeoJSON | null>(null)
  const { appearsAs } = useTheme()
  const isVisible = layers.filter(layer => layer.id === "supply-routes").some(layer => layer.visible)

  useEffect(() => {
    if (!mapInstance) return

    // Remove existing layer if it exists
    if (SupplyRoutesRef.current) {
      mapInstance.removeLayer(SupplyRoutesRef.current)
    }

    if (!isVisible) return

    const supplyRoutesColor = (appearsAs: Theme | undefined) => appearsAs === "dark" ? "#8a8500" : "#fda726"

    // Add new layer
    SupplyRoutesRef.current = L.geoJSON(SupplyRoutesData as GeoJSON.GeoJSON, {
      bubblingMouseEvents: true,
      style: () => ({
        color: supplyRoutesColor(appearsAs),
        weight: 4,
        opacity: 0.7,
        fillOpacity: 0.35,
        fillColor: supplyRoutesColor(appearsAs),
        lineJoin: "round",
      }),
      onEachFeature: (feature, layer) => {
        const popUpContent = `
          <div class="p-2">
            <div class="text-sm font-bold">Supply Route</div>
            <div class="text-sm"><span class="font-bold">From-To:</span> ${feature.properties.FromTo}</div>
          </div>
        `
        layer.bindPopup(popUpContent)
      },
    }).addTo(mapInstance)

    // Clean up function
    return () => {
      if (SupplyRoutesRef.current) {
        mapInstance.removeLayer(SupplyRoutesRef.current)
        SupplyRoutesRef.current = null
      }
    }
  }, [mapInstance, appearsAs, isVisible])

  return null
}

export default SupplyRouteLayer