import L from "leaflet"
import { useMapContext } from "@/context/map-context"
import { useEffect, useRef, useState } from "react"
import PopulatedPlacesData from "@/data/populated_places.json"
import { useTheme } from "@/context/theme-provider"
import { useDebouncedCallback } from "use-debounce";

interface PopulatedPlacesProps {
  isVisible: boolean
}

export function PopulatedPlaces({ isVisible }: PopulatedPlacesProps) {
  const { mapInstance, zoom } = useMapContext()
  const { appearsAs, theme } = useTheme()
  const layerRef = useRef<L.GeoJSON | null>(null)

  const [color, setColor] = useState("#C804C1")
  const debouncedSetColor = useDebouncedCallback(setColor, 100)

  useEffect(() => {
    const isDark = theme === "dark" || (theme === "system" && appearsAs === "dark")
    function getColor(zoom: number) {
      // return "#C804C1"
      if (zoom < 8.5) {
        return isDark ? "#815000" : "#C804C1"
      } else {
        return isDark ? "#FF9D00" : "#B300FF"
      }
    }

    function getFillColor(zoom: number) {
      if (zoom < 8.5) {
        return isDark ? "#815000" : "#C804C1"
      } else {
        return isDark ? "#FF9D00" : "#E8900A"
      }
    }

    function getFillOpacity(zoom: number) {
      if (zoom < 8.5) {
        return 0.15
      } else {
        return 0.35
      }
    }

    function getOpacity(zoom: number) {
      if (zoom < 8.5) {
        return 0.2
      } else {
        return isDark ? 0.15 : 1
      }
    }

    function getRadius(zoom: number) {
      if (zoom < 8.5) {
        return 0.25
      } else {
        return 2
      }
    }

    if (!mapInstance) return

    if (layerRef.current && isVisible) {
      mapInstance.removeLayer(layerRef.current)
      layerRef.current = null
    }

    // #815000
    if (isVisible) {
      if (!layerRef.current) {
        const layer = L.geoJSON(PopulatedPlacesData as GeoJSON.FeatureCollection, {
          pointToLayer: (_feature, latlng) => {
            return L.circleMarker(latlng, {
              radius: getRadius(zoom),
              color: getColor(zoom),
              weight: 1,
              opacity: getOpacity(zoom),
              fillOpacity: getFillOpacity(zoom),
              fillColor: getFillColor(zoom),
            })
          },
          // style: () => ({
          //   color:
          //   weight: 2,
          //   opacity: 0.7,
          //   fillOpacity: 0.35,
          //   fillColor: theme === "dark" ? "#357981" : "#333333",
          // }),
        }).addTo(mapInstance)
        layerRef.current = layer
      }
    } else {
      if (layerRef.current) {
        mapInstance.removeLayer(layerRef.current)
        layerRef.current = null
      }
    }
  }, [mapInstance, isVisible, appearsAs, theme, color, zoom])

  return null
  // return <input type="color" value={color} onChange={(e) => debouncedSetColor(e.target.value)} />
}
      