import { useEffect, useState } from "react";
import { fetchAlertsData } from "@/lib/api";
import type { AlertType } from "@/types/events";
import { alertStyles } from "@/components/alerts";

export default function AlertsTable() {
  const [isLoading, setIsLoading] = useState(true);

  const fetchAlerts = async () => {
    setIsLoading(true);
    try {
      const response = await fetchAlertsData();
      return response;
    } catch (error) {
      console.error("Error fetching alerts:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  useEffect(() => {
    fetchAlerts().then((data) => setAlerts(data));
  }, []);

  return (
    <div className="space-y-6 pb-6">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="space-y-4">
          {alerts.map((entry) => (
            <div
              key={entry.id}
              className="border rounded overflow-hidden">
              {/* Table Header */}
              <div className="grid grid-cols-7 bg-slate-100 dark:bg-slate-900 border-b">
                <div className="px-4 py-3 font-medium border-x">Date</div>
                <div className="px-4 py-3 font-medium border-x">State</div>
                <div className="px-4 py-3 font-medium border-x">County</div>
                <div className="px-4 py-3 font-medium border-x">
                  Timeline {entry.timeline}
                </div>
                <div className="px-4 py-3 font-medium border-x">Sources</div>
                <div className="px-4 py-3 font-medium border-x col-span-2">Summary</div>
              </div>

              {/* Table Content */}
              <div className="grid grid-cols-7">
                {/* First column with alert types and date */}
                <div className="bg-slate-200 dark:bg-slate-700 p-4">
                  <div className="mb-2 text-sm">{entry.date.toLocaleDateString()}</div>
                  <div className="space-y-2">
                    {entry.types.map((alertType, alertIndex) => (
                      <div
                        key={alertIndex}
                        className="px-4 py-2 text-center rounded text-slate-900 flex items-center justify-center"
                        style={{
                          backgroundColor: alertStyles[alertType as keyof typeof alertStyles].color,
                        }}>
                        {alertType}
                      </div>
                    ))}
                  </div>
                </div>
                {/* State Column */}
                <div className="p-4 flex items-center justify-center border-x">
                  <div className="text-sm">{entry.location.admin1}</div>
                </div>

                {/* County Column */}
                <div className="p-4 flex items-center justify-center border-x">
                  <div className="text-sm">{entry.location.admin2}</div>
                </div>

                {/* Timeline Column */}
                <div className="p-4 flex items-center justify-center border-x">
                  <div className="text-sm">
                    {entry.narrative_id}
                  </div>
                </div>

                {/* Sources Column */}

                <div className="p-4 flex items-center justify-center border-x">
                  {entry.sources_referenced && (
                    <div className="text-sm">{entry.sources_referenced.join(", ")}</div>
                  )}
                </div>

                <div className="p-4 flex items-center justify-center border-x col-span-2">
                  <div className="text-sm">{entry.narrative_summary || ""}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
