import { Button } from "@/components/ui/button"

function ErrorState({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) {
  console.error(error);
  return (
    <div className="text-center w-full">
      <h1 className="text-xl font-semibold mb-2">Sorry About This</h1>
      <p className="text-muted-foreground">Something went wrong! Please reload.</p>
      <pre className="text-muted-foreground">{error.message}</pre>
      <Button className="mt-4" onClick={() => window.location.reload()}>Retry?</Button>
      <Button className="mt-4" onClick={resetErrorBoundary}>Reload</Button>
    </div>
  )
}

export default ErrorState
