import { createContext, useContext, useState, useEffect, type ReactNode, useCallback, useRef } from "react"
import L from "leaflet"
import { fetchLayerData, fetchAlertsData } from "@/lib/api"
import { type Alert } from "@/types/alerts"
import { parse, isSameDay } from "date-fns"

export interface Layer {
  id: string
  name: string
  type: "geojson" | "csv" | "marker" | "raster"
  category: string
  group: string
  tabAssociations: string[]
  visible: boolean
  disabled?: boolean
  data?: any
  tileUrl?: string
  tileUrlsByDate?: Record<string, string>
  leafletLayer?: L.Layer
  color: string
  opacity?: number
  minZoom?: number
  maxZoom?: number
  date?: string // Optional date for time-based filtering
}

interface DateRange {
  start: Date
  end: Date
}

interface MapContextType {
  mapInstance: L.Map | null
  selectedDate: Date | null
  dateRange: DateRange | null
  setMapInstance: (map: L.Map) => void
  setSelectedDate: (date: Date | null) => void
  activeCategory: string
  setActiveCategory: (category: string) => void
  layers: Layer[]
  filteredLayers: Layer[]
  alerts?: Alert[]
  filteredAlerts?: Alert[]
  activeAlerts?: string[]
  toggleLayer?: (id: string) => void
  focusOnAlert?: (id: string) => void
  filterLayersByCategory: (category: string) => void
  searchQuery?: string
  setSearchQuery: (query: string) => void
  isSearchVisible?: boolean
  setIsSearchVisible: (visible: boolean) => void
  showingTopo: boolean
  baseLayer?: L.TileLayer
  setBaseLayer: (layer: L.TileLayer) => void
  topoLayer?: L.TileLayer
  setTopoLayer: (layer: L.TileLayer) => void
  mapOrAlert: string
  setMapOrAlert: (mapOrAlert: string) => void
  zoom: number
  setZoom: (zoom: number) => void
  sidebarOpen: boolean
  toggleSidebar: () => void
}

const MapContext = createContext<MapContextType | undefined>(undefined)

// Admin region coordinates for mock data
const adminCoords: Record<string, [number, number]> = {
  Jonglei: [8.5, 31.5],
  "Eastern Equatoria": [4.5, 33.5],
  "Western Equatoria": [5.5, 28.0],
  "Central Equatoria": [4.8, 31.0],
  "Western Bahr el Ghazal": [8.5, 25.5],
}

// Helper function to format date as YYYY-MM-DD
const formatDateForComparison = (date: Date): string => {
  return date.toISOString().split("T")[0]
}

// Helper function to check if a date string matches a target date
// FIXME use isSameDay
const datesMatch = (dateStr: string | undefined, targetDate: Date): boolean => {
  if (!dateStr) return true // Items without dates are visible on all dates
  const itemDate = parse(dateStr, "yyyy-MM-dd", new Date())
  return formatDateForComparison(itemDate) === formatDateForComparison(targetDate)
}

// Fix the activeAlerts initialization to ensure alerts are displayed by default
export function MapProvider({ children }: { children: ReactNode }) {
  const [mapInstance, setMapInstance] = useState<L.Map | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date("2026-06-15"))
  const [dateRange, setDateRange] = useState<DateRange | null>({
    start: parse("2026-05-01", "yyyy-MM-dd", new Date()),
    end: parse("2026-09-30", "yyyy-MM-dd", new Date()),
  })

  const [activeCategory, setActiveCategory] = useState<string>("conflict-risk")

  const [layers, setLayers] = useState<Layer[]>([])
  const [filteredLayers, setFilteredLayers] = useState<Layer[]>([])

  const [alerts, setAlerts] = useState<Alert[]>([])
  const [filteredAlerts, setFilteredAlerts] = useState<Alert[]>([])

  // Initialize all alerts as active by default
  const [activeAlerts, setActiveAlerts] = useState<string[]>([])

  const [searchQuery, setSearchQuery] = useState<string>("")
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false)

  // Use refs to track active layers and markers
  const activeLayersRef = useRef(new Map<string, L.Layer>())
  const activeAlertsRef = useRef(new Map<string, L.Layer>())

  // Topo layer state
  const [showingTopo, setShowingTopo] = useState<boolean>(false)
  const [baseLayer, setBaseLayer] = useState<L.TileLayer | null>(null)
  const [topoLayer, setTopoLayer] = useState<L.TileLayer | null>(null)

  const [zoom, setZoom] = useState<number>(7)

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)

  const toggleSidebar = (open?: boolean) => {
    setSidebarOpen(open ?? !sidebarOpen)

    window.setTimeout(() => {
      mapInstance?.invalidateSize();
    }, 5);
  };

  // Handle checking if we should show topo layer
  useEffect(() => {
    if (layers.filter(layer => layer.id === "topographic" && layer.visible).length > 0) {
      setShowingTopo(true)
    } else {
      setShowingTopo(false)
    }
  }, [layers])

  // Fetch initial layer data
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Fetch layer data
        const layerData = await fetchLayerData()
        setLayers(layerData)

        // Fetch alerts data
        const alertsData = await fetchAlertsData()
        setAlerts(alertsData)
      } catch (error) {
        console.error("Failed to load initial data:", error)
      }
    }

    loadInitialData()
  }, [])

  // Filter layers and alerts based on selected date
  useEffect(() => {
    if (!selectedDate) {
      setFilteredLayers(layers)
      setFilteredAlerts(alerts)
      return
    }

    // Filter layers based on date
    const newFilteredLayers = layers.map((layer) => {
      // Create a copy of the layer
      const layerCopy = { ...layer }

      // Filter data based on date if applicable
      if (layer.type === "geojson" && layer.data) {
        // Filter GeoJSON features
        // console.groupCollapsed(layer.id)
        // console.log('layer', layer.id, layer.data);
        if (layer.data.features) {
          layerCopy.data = {
            ...layer.data,
            features: layer.data.features.filter((feature: any) => {
              // Keep features without dates or with matching dates
              const date = feature.properties?.date
              // console.log(feature, date, !date || datesMatch(date, selectedDate))
              return !date || datesMatch(date, selectedDate)
            }),
          }
        }
        // console.groupEnd()
      } else if (layer.type === "csv" || layer.type === "marker") {
        // Filter array data
        if (Array.isArray(layer.data)) {
          layerCopy.data = layer.data.filter((item: any) => {
            // Keep items without dates or with matching dates
            return !item.date || datesMatch(item.date, selectedDate)
          })
        }
      } else if (layer.type === "raster" && layer.tileUrlsByDate) {
        // Handle date-specific tile URLs
        const dateStr = formatDateForComparison(selectedDate)
        if (layer.tileUrlsByDate[dateStr]) {
          layerCopy.tileUrl = layer.tileUrlsByDate[dateStr]
        }
      }

      return layerCopy
    })

    setFilteredLayers(newFilteredLayers)

    // Filter alerts based on date
    const newFilteredAlerts: Alert[] = alerts.filter((alert) => {
      return isSameDay(alert.date, selectedDate)
    })

    setFilteredAlerts(newFilteredAlerts)

    // Update active alerts to only include those that match the selected date
    setActiveAlerts((prev) =>
      prev.filter((alertId) => {
        const alert = newFilteredAlerts.find((a) => a.id === alertId)
        return !!alert
      }),
    )
  }, [selectedDate, layers, alerts])

  // Clean up all layers from the map
  const cleanupAllLayers = useCallback(() => {
    if (!mapInstance) return

    // Remove all layer markers
    activeLayersRef.current.forEach((layer) => {
      mapInstance.removeLayer(layer)
    })
    activeLayersRef.current.clear()

    // Remove all alert markers
    activeAlertsRef.current.forEach((layer) => {
      mapInstance.removeLayer(layer)
    })
    activeAlertsRef.current.clear()

  }, [mapInstance])

  // Focus on active alerts when they change
  const focusOnAlert = useCallback(
    (alertId: string) => {
      if (!mapInstance) return

      const alert = filteredAlerts.find((a) => a.id === alertId)
      if (!alert) return

      // Use requestAnimationFrame to ensure the map is ready
      requestAnimationFrame(() => {
        try {
          if (alert.latitude && alert.longitude) {
            // Zoom to the alert point
            mapInstance.setView([alert.latitude, alert.longitude], 10)
          } else if (alert.admin2_region) {
            // Zoom to the admin region
            const coords = adminCoords[alert.admin2_region] || [7.5, 30.0]
            mapInstance.setView(coords, 9)
          } else if (alert.geometry) {
            // Try to calculate bounds from geometry
            const layer = activeAlertsRef.current.get(alert.id)
            if (layer && "getBounds" in layer && typeof layer.getBounds === "function") {
              const bounds = layer.getBounds()
              mapInstance.fitBounds(bounds, {
                padding: [50, 50],
                maxZoom: 10,
              })
            }
          }
        } catch (error) {
          console.error("Error focusing on alert:", error)
        }
      })
    },
    [mapInstance, filteredAlerts],
  )

  // Cleanup all layers when component unmounts
  useEffect(() => {
    return () => {
      if (mapInstance) {
        // Clean up all layers
        activeLayersRef.current.forEach((layer) => {
          mapInstance.removeLayer(layer)
        })
        activeAlertsRef.current.forEach((layer) => {
          mapInstance.removeLayer(layer)
        })
      }
    }
  }, [mapInstance])

  const toggleLayer = useCallback((id: string) => {
    setLayers((prevLayers) =>
      prevLayers.map((layer) => {
        if (layer.id === id) {
          return { ...layer, visible: !layer.visible }
        }
        return layer
      }),
    )
  }, [])

  const filterLayersByCategory = useCallback((category: string) => {
    setActiveCategory(category)

    // Auto-show layers associated with the selected category
    setLayers((prevLayers) =>
      prevLayers.map((layer) => {
        // If the layer is associated with the selected category, make it visible
        return { ...layer, visible: layer.tabAssociations.includes(category) }
      }),
    )
  }, [])

  const handleSetSelectedDate = useCallback((date: Date | null) => {
    setSelectedDate(date)
  }, [])

  const handleSetActiveCategory = useCallback((category: string) => {
    setActiveCategory(category)
  }, [])

  useEffect(() => {
    if (!mapInstance) return

    if (!baseLayer || !topoLayer) return

    if (showingTopo) {
      mapInstance.addLayer(topoLayer)
      mapInstance.removeLayer(baseLayer)
    } else {
      mapInstance.removeLayer(topoLayer)
      mapInstance.addLayer(baseLayer)
    }
  }, [mapInstance, showingTopo, baseLayer, topoLayer])

  return (
    <MapContext.Provider
      value={{
        mapInstance,
        selectedDate,
        dateRange,
        setMapInstance,
        setSelectedDate: handleSetSelectedDate,
        activeCategory,
        setActiveCategory: handleSetActiveCategory,
        layers,
        filteredLayers,
        alerts,
        filteredAlerts,
        activeAlerts,
        toggleLayer,
        focusOnAlert,
        filterLayersByCategory,
        searchQuery,
        setSearchQuery,
        isSearchVisible,
        setIsSearchVisible,
        showingTopo,
        baseLayer,
        setBaseLayer,
        topoLayer,
        setTopoLayer,
        zoom,
        setZoom,
        sidebarOpen,
        toggleSidebar
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export function useMapContext() {
  const context = useContext(MapContext)
  if (context === undefined) {
    throw new Error("useMapContext must be used within a MapProvider")
  }
  return context
}
