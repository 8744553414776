import { useState } from "react"

import { PanelLeft } from "lucide-react"

import { useMapContext } from "@/context/map-context"

import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Button } from "@/components/ui/button"
import { ModeToggle } from "@/components/theme-selector"
import { Separator } from "@/components/ui/separator"
import { ViewOption } from "@/components/map-dashboard"

interface HeaderProps {
  handleViewSelection: (view: ViewOption) => void
  activeView: ViewOption
}

export function Header({ handleViewSelection, activeView }: HeaderProps) {
  const [activeTab, setActiveTab] = useState("conflict-risk")
  const {filterLayersByCategory, toggleSidebar} = useMapContext()

  const handleTabChange = (tab: string) => {
    if (TabList.find(t => t.value === tab)?.enabled) {
      setActiveTab(tab)
      filterLayersByCategory(tab)
    }
  }

  const TabList = [
    {
      value: "conflict-risk",
      label: "Conflict Risk Today",
      purpose: "Indentify areas with high potential for violence or instability",
      enabled: true,
    },
    {
      value: "flood-impacts",
      label: "Flood Impacts",
      purpose: "Assess the extent and impact of recent or ongoing floods",
      enabled: true,
    },
    {
      value: "higher-ground",
      label: "Higher Ground",
      purpose: "Identify areas with higher elevation that may provide refuge during floods",
      enabled: true,
    },
    {
      value: "displacement",
      label: "Displacement Overview",
      purpose: "Understand movement and resettlement patterns",
      enabled: true,
    },
    {
      value: "drought-stress",
      label: "Drought Stress",
      purpose: "Monitor regions under agricultural and water stress",
      enabled: false,
    },
    {
      value: "flood-risk-zones",
      label: "Flood Risk Zones",
      purpose: "Identify historically flood-prone areas and terrain vulnerability",
      enabled: false,
    },
    {
      value: "field-operations",
      label: "Field Operations",
      purpose: "Support operational planning using patrol and civil affairs data",
      enabled: false,
    },
    {
      value: "crop-stress-overview",
      label: "Crop Stress Overview",
      purpose: "Visualize areas at risk of food insecurity or failed harvests",
      enabled: false,
    },
    {
      value: "environmental-trends",
      label: "Environmental Trends",
      purpose: "Provide long-term environmental insights",
      enabled: false,
    }
  ]

  const showingMap = activeView === "map"

  function BottomHeader() {
    return (
      <div className="flex h-12 border-t items-center px-4">
        <Button
          data-sidebar="trigger"
          variant="ghost"
          size="icon"
          className="h-7 w-7"
          onClick={() => toggleSidebar()}
        >
          <PanelLeft />
          <span className="sr-only">Toggle Sidebar</span>
        </Button>
        <Separator orientation="vertical" className="mr-2 h-4" />
        <div className="ml-auto overflow-hidden">
          <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
            <div className="overflow-auto">
              <TabsList className="inline-flex w-max px-1">
                {TabList.map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    aria-disabled={!tab.enabled}
                    value={tab.value}
                    className={`px-4 ${tab.enabled ? 'text-primary hover:shadow-sm cursor-pointer' : 'ghost cursor-not-allowed'}`}
                    title={tab.purpose}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
          </Tabs>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col border-b bg-background print:hidden">
      <div className="flex px-4 py-1">
        <img src="/geoharmony.svg" alt="GeoHarmony" className="h-9 mr-1" />
        <h1 className="text-lg font-bold text-primary align-bottom leading-9">GeoHarmony</h1>

        <div className="flex items-center gap-2 mx-4">
          <Button variant={activeView === "map" ? "headerSelected" :  "header"} onClick={() => handleViewSelection("map")} className={`h-9`}>
            Map
          </Button>
          <Button variant={activeView === "reports" ? "headerSelected" : "header"} onClick={() => handleViewSelection("reports")} className={`h-9`}>
            Reports
          </Button>
          <Button variant={activeView === "alerts" ? "headerSelected" : "header"} onClick={() => handleViewSelection("alerts")} className={`h-9`}>
            Alerts
          </Button>
        </div>

        <div className="ml-auto flex gap-2">
          <div className="flex items-center gap-2 rounded-full bg-green-100 dark:bg-accent px-3">
            <div className="h-2 w-2 rounded-full bg-green-500"></div>
            <span className="text-sm">UNMISS User</span>
          </div>
          <ModeToggle />
        </div>
      </div>

      {activeView === "map" && <BottomHeader />}
    </div>
  )
}

