export default function RegionTabs() {
  const regions = [
    { name: "All States", active: true },
    { name: "Central Equatoria", active: false },
    { name: "Eastern Equatoria", active: false },
    { name: "Jonglei", active: false },
    { name: "Lakes", active: false },
    { name: "Northern Bahr el Ghazal", active: false },
    { name: "Unity", active: false },
    { name: "Upper Nile", active: false },
    { name: "Warrap", active: false },
    { name: "Western Bahr el Ghazal", active: false },
    { name: "Western Equatoria", active: false },
  ]

  return (
    <div className="border border-muted-foreground rounded mb-8 flex overflow-x-auto">
      {regions.map((region, index) => (
        <button
          key={index}
          className={`px-4 py-3 whitespace-nowrap ${
            region.active ? "bg-primary text-primary-foreground" : "bg-muted text-primary hover:bg-muted-foreground/50 cursor-not-allowed"
          }`}
        >
          {region.name}
        </button>
      ))}
    </div>
  )
}

