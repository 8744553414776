import { alertStyles, alertTypes } from "@/components/alerts"
import { useMapContext } from "@/context/map-context"

export default function AlertStats() {
  const { alerts } = useMapContext()

  const alertTypeCounts = alertTypes.reduce((acc, alertType) => {
    const count = alerts?.filter(alert => alert.types.includes(alertType)).length || 0
    return { ...acc, [alertType]: count }
  }, {} as Record<string, number>)

  const totalAlerts = Object.values(alertTypeCounts).reduce((sum, count) => sum + count, 0)

  return (
    <div className="mb-8">
      {/* Total Alerts Counter */}
      <div className="flex justify-end mb-4">
        <div className="text-right">
          <div className="text-6xl font-light text-slate-400">{totalAlerts}</div>
          <div className="text-2xl font-medium text-slate-400">ALERTS</div>
        </div>
      </div>

      {/* Alert Type Stats with Visual Connection */}
      <div className="grid grid-cols-7 gap-4 mb-6">
        {alertTypes.map((alert, index) => (
          <div key={index} className="flex flex-col col-span-1 rounded overflow-hidden shadow-sm">
            {/* Count Display */}
            <div className="w-full py-2 text-center rounded-t bg-slate-100 dark:bg-slate-900 border-t border-x border-slate-300 dark:border-slate-500">
              <div className="text-2xl font-medium">{alertTypeCounts[alert]}</div>
              <div className="text-xs text-slate-500">ALERTS</div>
            </div>

            {/* Alert Type Button */}
            <div
              className="w-full py-2 text-center rounded-b font-medium border-b border-x border-slate-300 dark:text-slate-900 dark:border-slate-500"
              style={{ backgroundColor: alertStyles[alert].color }}
            >
              {alert}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

