import { Button } from "@/components/ui/button"
import { Printer } from "lucide-react"

export function PrintButton() {
    return (
        <Button
            variant="secondary"
            size="icon"
            className="absolute top-4 left-4 z-[9999] shadow-md hover:bg-gray-100 print:hidden hover:cursor-pointer"
            title="Print map"
            onClick={() => window.print()}
        >
            <Printer className="h-5 w-5" />
            <span className="sr-only">Print</span>
        </Button>
    )
}