import { MapProvider } from "@/context/map-context"
import { MapView } from "@/components/map-view"
import { Header } from "@/components/header"
import type { Event } from "@/types/events"
import { Sidebar } from "@/components/sidebar"
import { useState } from "react"
import { Alerts } from "@/components/alerts"
import reportImage from "@/assets/Report.jpg"
import { ThemeProvider } from "@/context/theme-provider"

interface MapDashboardProps {
  events: Event[]
}

export type ViewOption = "map" | "reports" | "alerts"

export function MapDashboard({ events }: MapDashboardProps) {
  const [selectedView, setSelectedView] = useState<ViewOption>("map")
  return (
    <MapProvider><ThemeProvider>
      <div className="flex h-screen flex-col">
        <Header handleViewSelection={setSelectedView} activeView={selectedView}/>
        {selectedView === "map" && (
          <div className="flex flex-1 overflow-hidden">
            <Sidebar />
            <MapView events={events} />
          </div>
        )}
        {selectedView === "alerts" && (
          <div className="flex flex-1 overflow-auto">
            <Alerts />
          </div>
        )}
        {selectedView === "reports" && (
          <div className="flex flex-1 overflow-auto justify-center bg-neutral-200 dark:bg-neutral-900">
            <img src={reportImage} alt="Report" className="dark:invert dark:grayscale-50 self-start w-full max-w-[2048px]" />
          </div>
        )}
      </div>
    </ThemeProvider></MapProvider>
  )
}

