import { useEffect, useRef, useState, useCallback } from "react"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import { useMapContext } from "@/context/map-context"
import { HomeButton } from "@/components/home-button"
import { EventMarkers } from "@/components/event-markers"
import { TimelinePanel } from "@/components/timeline-panel"
import { filterEventsByDateRange } from "@/lib/events-utils"
import type { Event } from "@/types/events"
import { DateSlider } from "@/components/slider"
import { AdminBoundaries } from "@/components/admin-boundaries"
import { UNMISSLayer } from "@/components/unmiss-layer"
import { IDPLayer } from "@/components/idp-layer"
import { ConflictEvents } from "@/components/conflict-events"
import { ConflictEventPoints } from "@/components/conflict-event-points"
import { UNMISSCivilAffairs } from "@/components/unmiss-civil-affairs"
import { UNMISSPatrol } from "@/components/unmiss-patrol"
import { FloodLayer } from "@/components/flood-layer"
import { RoadLayer } from "@/components/road"
import { RiverLayer } from "@/components/river"
import { PopulatedPlaces } from "@/components/populated-places"
import { ErrorBoundary } from "react-error-boundary";
import { PrintButton } from "@/components/print-button"
import ErrorState from "@/components/error-state";
import SupplyRouteLayer from "@/components/supply-routes"

interface MapViewProps {
  events: Event[]
}

export function MapView({ events }: MapViewProps) {
  const mapRef = useRef<L.Map | null>(null)
  const { setMapInstance, selectedDate, setSelectedDate, dateRange, layers, setBaseLayer, setTopoLayer, setZoom } = useMapContext()
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const isInitializedRef = useRef(false)
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([])

  // Filter events based on selected date
  useEffect(() => {
    if (selectedDate) {
      const filtered = filterEventsByDateRange(events, selectedDate, 10)
      setFilteredEvents(filtered)
    } else {
      setFilteredEvents([])
    }
  }, [events, selectedDate])

  useEffect(() => {
    if (!mapContainerRef.current) return

    // Initialize map if it doesn't exist
    if (!mapRef.current) {
      // Make sure the container has dimensions before initializing the map
      const container = mapContainerRef.current
      const containerHeight = container.clientHeight
      const containerWidth = container.clientWidth

      if (containerHeight === 0 || containerWidth === 0) {
        // If container dimensions are not ready, wait a bit and try again
        const timer = setTimeout(() => {
          if (container && !isInitializedRef.current) {
            initializeMap()
          }
        }, 100)
        return () => clearTimeout(timer)
      }

      initializeMap()
    }

    function initializeMap() {
      if (isInitializedRef.current || !mapContainerRef.current) return

      try {
        const defaultZoom = 5.5
        mapRef.current = L.map(mapContainerRef.current, {
          center: [7.5, 30],
          zoom: defaultZoom,
          minZoom: 5,
          zoomControl: true,
          attributionControl: true,
          scrollWheelZoom: true,
          closePopupOnClick: false,
          preferCanvas: true,
          zoomSnap: 0.5,
          zoomDelta: 0.5,
          fadeAnimation: false,
          markerZoomAnimation: false,
        })
        mapRef.current.zoomControl.setPosition('bottomleft');
        setZoom(defaultZoom);

        window.mapRef = mapRef

        // Add base tile layer
        const baseLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          minZoom: 3,
          maxZoom: 19,
          bubblingMouseEvents: true,
        }).addTo(mapRef.current)
        setBaseLayer(baseLayer)
        setTopoLayer(L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a target="_blank" href="https://www.opentopomap.org/copyright">OpenTopoMap</a> contributors',
          minZoom: 3,
          maxZoom: 14,
          bubblingMouseEvents: true,
        }))

        // Disable Ukrainian flag from attribution control
        mapRef.current.attributionControl.setPrefix("<a target='_blank' href='https://leafletjs.com'>Leaflet</a>")

        mapRef.current.on("zoomend", () => {
          if (mapRef.current) {
            setZoom(mapRef.current.getZoom())
          }
        })
        // Save map instance to context
        setMapInstance(mapRef.current)
        isInitializedRef.current = true

      } catch (error) {
        console.error("Error initializing map:", error)
      }
    }

    // TODO: Add proper cleanup
    // return () => {
    //   if (mapRef.current) {
    //     try {
    //       const mapRefCurrent = mapRef.current
    //       mapRef.current.remove()
    //       // container?.remove()
    //     } catch (error) {
    //       console.error("Error removing map:", error)
    //     }
    //     mapRef.current = null
    //     isInitializedRef.current = false
    //   }
    // }
  }, [setMapInstance])

  // Ensure map resizes properly when container dimensions change
  useEffect(() => {
    if (!mapRef.current) return

    const handleResize = () => {
      if (mapRef.current) {
        try {
          mapRef.current.invalidateSize()
        } catch (error) {
          console.error("Error resizing map:", error)
        }
      }
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="relative flex flex-grow flex-col h-full">
      <ErrorBoundary FallbackComponent={ErrorState} onError={(error, errorInfo) => console.error(error, errorInfo)}>
      <div ref={mapContainerRef} className="flex-1 w-full relative" />
      <HomeButton mapInstance={mapRef.current} />
      <PrintButton />
      <TimelinePanel
        events={events}
        selectedDate={selectedDate}
        mapInstance={mapRef.current}
      />

      {/* Map layers */}
      <ConflictEvents
        isVisible={layers.filter(layer => layer.id === "adm2-crisis").some(layer => layer.visible)}
        selectedDate={selectedDate}
      />

      <ConflictEventPoints
        isVisible={layers.filter(layer => layer.id === "conflict-events").some(layer => layer.visible)}
      />
      <UNMISSCivilAffairs
        isVisible={layers.filter(layer => layer.id === "unmiss-civil-affairs").some(layer => layer.visible)}
      />
      <UNMISSPatrol
        isVisible={layers.filter(layer => layer.id === "unmiss-patrol").some(layer => layer.visible)}
      />

      <EventMarkers
        events={filteredEvents}
        mapInstance={mapRef.current}
      />
      <AdminBoundaries
        mapInstance={mapRef.current}
        admin1Enabled={layers.filter(layer => layer.id === "admin1").some(layer => layer.visible)}
        admin2Enabled={layers.filter(layer => layer.id === "admin2").some(layer => layer.visible)}
      />

      <UNMISSLayer isVisible={layers.filter(layer => layer.id === "unmiss").some(layer => layer.visible)}/>
      <IDPLayer isVisible={layers.filter(layer => layer.id === "idp").some(layer => layer.visible)}/>
      {/* <PopulatedPlaces isVisible={layers.filter(layer => layer.id === "populated_places").some(layer => layer.visible)}/> */}
      <PopulatedPlaces isVisible={layers.filter(layer => layer.id === "populated_places").some(layer => layer.visible)}/>
      <FloodLayer 
        isVisible={layers.filter(layer => layer.id === "flood").some(layer => layer.visible)}
      />
      <RiverLayer 
        isVisible={layers.filter(layer => layer.id === "river").some(layer => layer.visible)}
      />
      <RoadLayer 
        isVisible={layers.filter(layer => layer.id === "road").some(layer => layer.visible)}
      />

      <SupplyRouteLayer />
      


      {/* <IDPLayer
        mapInstance={mapRef.current}
        data={IDP_DATA}
        enabled={true}
      />
      <CrisisLayer
        data={ADM2_CRISIS}
        enabled={true}
        mapInstance={mapRef.current}
      />
      <FloodwatchLayer
        mapInstance={mapRef.current}
        data={floodwatchTileUrls}
        enabled={true}
      /> */}


{/* Show CoordinatesDisplay only in dev, not prod */}
{/* {process.env.NODE_ENV === "development" && <CoordinatesDisplay mapInstance={mapRef.current} />} */}
      <DateSlider selectedDate={selectedDate} setSelectedDate={setSelectedDate} dateRange={dateRange} />
    </ErrorBoundary>
    </div>
  )
}
