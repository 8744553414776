import { Download } from "lucide-react"
import AlertsTable from "@/components/alerts-table"
import RegionTabs from "@/components/region-tabs"
import AlertStats from "@/components/alert-stats"
import TimeFilters from "@/components/time-filters"

export const alertStyles: Record<string, { color: string }> = {
  FLOOD: { color: "#afd3ff" },
  DROUGHT: { color: "#ffe2a8" },
  CROP: { color: "#ffad64" },
  TRANSHUMANCE: { color: "#ef62ff" },
  CONFLICT: { color: "#ff6565" },
  DISPLACEMENT: { color: "#357981" },
  HEALTH: { color: "#e50000" },
}

export const alertTypes = Object.keys(alertStyles)

export function Alerts() {
  return (
    <main className="max-w-7xl mx-auto px-4 py-6 pb-8">
      <div className="flex justify-between items-center mb-6">
        <TimeFilters />
        <button className="flex items-center space-x-2 text-muted-foreground">
          <span>SHARE REPORT</span>
          <Download size={18} />
        </button>
      </div>

      {/* Region Tabs */}
      <RegionTabs />

      {/* Alert Stats */}
      <AlertStats />

      {/* Alert Tables */}
      <AlertsTable />
    </main>
  )
}