import { useState, useEffect } from "react"
import { AlertCircle, ChevronLeft, MapPin, ArrowLeft, Info  } from "lucide-react"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { Badge } from "@/components/ui/badge"
import { Button, buttonVariants } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { filterEventsByTimelineId } from "@/lib/events-utils"
import { useMapContext } from "@/context/map-context"
import type { Event } from "@/types/events"
import type * as L from "leaflet"
import { parse, subDays, compareDesc, isBefore, isAfter, isWithinInterval } from "date-fns"

interface TimelinePanelProps {
  events: Event[]
  selectedDate: Date | null
  mapInstance: L.Map | null
  onTimelineSelect?: (timelineId: string) => void
}

export function TimelinePanel({ events, selectedDate, mapInstance, onTimelineSelect }: TimelinePanelProps) {
  const { setSelectedDate } = useMapContext()
  const sortedEvents = events.sort((a, b) => compareDesc(a.date, b.date))
  const [isOpen, setIsOpen] = useState(true)
  const [focusedTimelineId, setFocusedTimelineId] = useState<string | null>(null)
  const [focusedEvents, setFocusedEvents] = useState<Event[]>([])
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([])

  // Filter events based on selected date (10-day window)
  useEffect(() => {
    if (!selectedDate) {
      setFilteredEvents([])
      return
    }

    const earliest = subDays(selectedDate, 10)

    const filtered = sortedEvents.filter((event) => {
      const eventDate = parse(event.date, "yyyy-MM-dd", new Date())
      return isWithinInterval(eventDate, { start: earliest, end: selectedDate })
    })

    setFilteredEvents(filtered)

    // Clear focused timeline if we change dates
    // setFocusedTimelineId(null)
    // setFocusedEvents([])
  }, [events, selectedDate, sortedEvents])

  // Auto-open panel if there are alerts
  useEffect(() => {
    const hasAlerts = filteredEvents.length > 0
    setIsOpen(hasAlerts)
  }, [filteredEvents.length])

  // Handle focusing on a specific timeline
  const handleFocusTimeline = (timelineId: string) => {
    if (!selectedDate) return

    // Get all events with this timeline ID up to the selected date
    const timelineEvents = filterEventsByTimelineId(events, timelineId)
    setFocusedEvents(timelineEvents)
    setFocusedTimelineId(timelineId)

    if (onTimelineSelect) {
      onTimelineSelect(timelineId)
    }
  }

  // Clear focused timeline
  const clearFocus = () => {
    setFocusedTimelineId(null)
    setFocusedEvents([])
  }

  // If no events, show empty state
  if (filteredEvents.length === 0 && !focusedTimelineId) {
    return (
      <Collapsible open={false} onOpenChange={setIsOpen} className="absolute top-16 right-4 z-[9999] w-70 bg-white rounded-md shadow-md print:hidden data-[state=closed]:w-fit select-none">
        <div className="p-3 border-b bg-background">
          <CollapsibleTrigger className="flex w-full items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <Info className="h-4 w-4 text-muted-foreground" />
              Alerts
            </div>
            <ChevronLeft className="h-4 w-4 text-muted-foreground cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent>
          <div className="flex items-center justify-between">
            <h2 className="text-base font-semibold flex items-center gap-2">
              <Info className="h-4 w-4 text-muted-foreground" />
              Alerts
            </h2>
            <ChevronLeft className="h-4 w-4 text-muted-foreground cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
          </div>
          <p className="text-sm text-muted-foreground mt-1">No alerts for the selected date.</p>
        </CollapsibleContent>
      </Collapsible>
    )
  }

  return (
      <Collapsible open={isOpen} onOpenChange={setIsOpen} className="absolute top-16 right-4 z-[9999] w-70 bg-white rounded-md shadow-md print:hidden data-[state=closed]:w-fit select-none opacity-75 hover:opacity-100">
        <div className="p-3 bg-background border-b">
          <CollapsibleTrigger className="flex w-full items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <AlertCircle className="h-4 w-4" />
              <h2 className="text-base font-semibold">
                {focusedTimelineId ? `Timeline ${focusedTimelineId}` : "Alerts"}
              </h2>
              <Badge variant="destructive" className="rounded-full px-2 py-0 text-xs text-white">
                {focusedTimelineId ? focusedEvents.length : filteredEvents.length}
              </Badge>
            </div>
            <div className="flex items-center">
              {focusedTimelineId && (
                <span
                  className={buttonVariants({ variant: "ghost", size: "icon", className: "h-7 w-7 mr-1 cursor-pointer"})}
                  onClick={(e) => {
                    e.stopPropagation()
                    clearFocus()
                  }}
                >
                  <ArrowLeft className="h-4 w-4" />
                </span>
              )}
              <ChevronLeft className={cn("h-4 w-4 transition-transform duration-200", isOpen && "-rotate-90")} />
            </div>
          </CollapsibleTrigger>
        </div>

        <CollapsibleContent>
          <div className="px-3 pb-3 max-h-[400px] overflow-auto dark:bg-black/80">
            <div className="space-y-2 pt-2">
              {(focusedTimelineId ? focusedEvents : filteredEvents).map((event, index) => (
                <div
                  key={`${event.narrative_id}-${event.date}-${index}`}
                  className={cn("rounded-md border p-2 transition-colors", "hover:bg-accent/20 hover:shadow shadow-accent")}
                  // onMouseEnter={() => {
                  //   if (mapInstance && event.location && event.location.geometry?.type === "Point") {
                  //     setLastMapPosition(mapInstance.getCenter())
                  //     const [long, lat] = event.location.geometry.coordinates
                  //     mapInstance.flyTo([lat, long], Math.round(mapInstance.getZoom()), { animate: true })
                  //   }
                  // }}
                  // onMouseLeave={() => {
                  //   if (mapInstance && lastMapPosition) {
                  //     mapInstance.flyTo(lastMapPosition, Math.round(mapInstance.getZoom()), { animate: true })
                  //   }
                  // }}
                >
                  <div className="flex items-start justify-between">
                    <div className="flex-1">
                      <div className="flex items-start justify-start">
                        <span className="h-2 w-2 mt-1.5 rounded-full inline-block bg-red-500 mr-1" />
                        <div className="flex flex-col items-start justify-between">
                          <h3 className="font-medium text-sm">
                            {event.location.name}
                          </h3>
                          <span className="text-xs text-muted-foreground" onClick={() => setSelectedDate(parse(event.date, "yyyy-MM-dd", new Date()))}>
                            {parse(event.date, "yyyy-MM-dd", new Date()).toLocaleDateString()}
                          </span>
                          <p className="text-xs mt-1">{event.event}</p>
                          {event.context && <p className="text-xs text-muted-foreground mt-1 italic">{event.context}</p>}
                          <div className="flex items-center mt-1">
                            <Badge variant="outline" className="text-tiny cursor-pointer"
                              onClick={() => {
                                if (!focusedTimelineId) {
                                  handleFocusTimeline(event.narrative_id)
                                }
                              }}>
                              Timeline {event.narrative_id}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-7 w-7 shrink-0 ml-1 cursor-pointer bg-accent border-1 shadow-sm hover:shadow-accent"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (mapInstance && event.location && event.location.geometry?.type === "Point") {
                          const zoom = event.location.zoom || 8
                          const [long, lat] = event.location.geometry.coordinates
                          mapInstance.flyTo([lat, long], zoom, { animate: true })
                        }
                      }}
                    >
                      <MapPin className="h-4 w-4 text-muted-foreground hover:text-slate-500" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
  )
}

