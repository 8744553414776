import L from "leaflet"
import { useMapContext } from "@/context/map-context"
import { useEffect, useRef } from "react"
import Road from "@/data/road.json"
import { useTheme } from "@/context/theme-provider"
import type { Theme } from "@/context/theme-provider"

export function RoadLayer({isVisible}: { isVisible: boolean }) {
  const { mapInstance } = useMapContext()
  const RoadLayerRef = useRef<L.GeoJSON | null>(null)

  const { appearsAs } = useTheme()

  // useEffect(() => {
  //   if (!mapInstance) return

  //   // Clean up function
  //   return () => {
  //     if (RoadLayerRef.current) {
  //       mapInstance.removeLayer(RoadLayerRef.current)
  //       RoadLayerRef.current = null
  //     }
  //   }
  // }, [mapInstance])

  useEffect(() => {
    if (!mapInstance) return

    // Remove existing layer if it exists
    if (RoadLayerRef.current) {
      mapInstance.removeLayer(RoadLayerRef.current)
    }

    const roadColor = (appearsAs: Theme | undefined) => appearsAs === "dark" ? "#A8A300" : "#FECE87"

    if (!isVisible) return

    RoadLayerRef.current = L.geoJSON(Road as GeoJSON.FeatureCollection, {
      style: () => ({
        className: 'road',
        color: roadColor(appearsAs),
        weight: 3,
        opacity: 0.7,
        fillOpacity: 0.4,
        fillColor: roadColor(appearsAs),
      }),
      onEachFeature: (feature, layer) => {
        const name = feature.properties.name
        const ref = feature.properties.ref
        const bridge = feature.properties.bridge
        const surface = feature.properties.surface
        const oneway = feature.properties.oneway
        const hasAnyInfo = name || ref || bridge || surface || oneway
        if (!hasAnyInfo) return
        const popUpContent = `
        <div class="p-2">
          <div class="text-base font-bold">Road</div>
          ${name ? `<div class="text-sm"><span class="font-bold">Name:</span> ${name}</div>` : ""}
          ${ref ? `<div class="text-sm"><span class="font-bold">Ref:</span> ${ref}</div>` : ""}
          ${bridge ? `<div class="text-xs"><span class="font-bold">Bridge:</span> ${bridge}</div>` : ""}
          ${surface ? `<div class="text-xs"><span class="font-bold">Surface:</span> ${surface}</div>` : ""}
          ${oneway ? `<div class="text-xs"><span class="font-bold">One-way:</span> ${oneway}</div>` : ""}
        </div>
        `
        layer.bindPopup(popUpContent)
      },
    }).addTo(mapInstance)

    // Clean up function
    return () => {
      if (RoadLayerRef.current) {
        mapInstance.removeLayer(RoadLayerRef.current)
        RoadLayerRef.current = null
      }
    }
  }, [mapInstance, isVisible, appearsAs])

  return null
}
