import type { Layer } from "@/context/map-context"
import { parse } from "date-fns"

import ADMIN1 from "@/data/adm1.json"
import ADMIN2 from "@/data/adm2.json"
import EVENTS from "@/data/unmiss-events-chronological.json"
import CONFLICT_EVENTS from "@/data/events.json"
import UNMISS from "@/data/UNMISS South Sudan Locations.json"
import IDP_DATA from "@/data/cccm_combined.json"
import FloodPolygon from "@/data/max_flood_extents.json"
import ADM2_CRISIS from "@/data/crisis-events.json"
import UNMISS_CIVIL_AFFIARS from "@/data/unmiss_civil_affairs_geo.json"
import UNMISS_PATROL from "@/data/unmiss_patrol_geo.json"
import ROAD from "@/data/road.json"
import RIVER from "@/data/river.json"


import { type Alert } from "@/types/alerts"


// Mock tile URLs by date for raster layers
const floodwatchTileUrls = {
  "2026-07-15": "https://storage.googleapis.com/geoharmony-tiles/march-15-2025-floodwatch-differences-from-historical-mean/{z}/{x}/{y}",
  "2026-07-16": "https://storage.googleapis.com/geoharmony-tiles/march-15-2025-floodwatch-differences-from-historical-mean/{z}/{x}/{y}",
  "2026-07-17": "https://storage.googleapis.com/geoharmony-tiles/march-15-2025-floodwatch-differences-from-historical-mean/{z}/{x}/{y}",
}

const precipitationTileUrls = {
  "2026-06-15": "https://storage.googleapis.com/geoharmony-tiles/march-15-2025-floodwatch-differences-from-historical-mean/{z}/{x}/{y}",
  "2026-06-16": "https://storage.googleapis.com/geoharmony-tiles/march-15-2025-floodwatch-differences-from-historical-mean/{z}/{x}/{y}",
  "2026-06-17": "https://storage.googleapis.com/geoharmony-tiles/march-15-2025-floodwatch-differences-from-historical-mean/{z}/{x}/{y}",
}

const droughtTileUrls = {
  "2026-08-15": "https://storage.googleapis.com/geoharmony-tiles/drought-severity-index/2025-03-15/{z}/{x}/{y}",
  "2026-08-16": "https://storage.googleapis.com/geoharmony-tiles/drought-severity-index/2025-03-16/{z}/{x}/{y}",
  "2026-08-17": "https://storage.googleapis.com/geoharmony-tiles/drought-severity-index/2025-03-17/{z}/{x}/{y}",
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function fetchAlertsData(): Promise<Alert[]> {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 500))

  return EVENTS.map((event) => ({
    ...event,
    date: parse(event.date, "yyyy-MM-dd", new Date()),
  })) as Alert[]
}

export async function fetchLayerData(): Promise<Layer[]> {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 500))

  return [
    {
      id: "admin1",
      name: "States",
      type: "geojson",
      category: "Geographic",
      group: "Geographic",
      tabAssociations: [],
      visible: true,
      data: ADMIN1,
      color: "#ff00ff",
    },

    {
      id: "admin2",
      name: "Payam",
      type: "geojson",
      category: "Geographic",
      group: "Geographic",
      tabAssociations: [],
      visible: false,
      data: ADMIN2,
      color: "#ff00ff",
    },
    {
      id: "road",
      name: "Roads",
      type: "geojson",
      category: "Infrastructure",
      group: "Infrastructure",
      tabAssociations: [],
      visible: false,
      data: ROAD,
      color: "#ff00ff",
    },
    {
      id: "supply-routes",
      name: "Supply Routes",
      type: "geojson",
      category: "Infrastructure",
      group: "Infrastructure",
      tabAssociations: [],
      visible: false,
      data: [],
      color: "#ff00ff",
    },
    {
      id: "river",
      name: "River",
      type: "geojson",
      category: "Geographic",
      group: "Geographic",
      tabAssociations: [],
      visible: false,
      data: RIVER,
      color: "#ff00ff",
    },
    {
      id: "adm2-crisis",
      name: "Monthly Summary",
      type: "geojson",
      category: "Conflict",
      group: "Conflict",
      tabAssociations: ["conflict-risk", "displacement"],
      visible: false,
      data: ADM2_CRISIS,
      color: "#333333",
    },
    {
      id: "conflict-events",
      name: "Daily Conflict Events",
      type: "geojson",
      category: "Conflict",
      group: "Conflict",
      tabAssociations: ["conflict-risk"],
      visible: true,
      data: CONFLICT_EVENTS,
      color: "#333333",
    },
    {
      id: "idp",
      name: "IDP",
      type: "geojson",
      category: "Humanitarian",
      group: "Humanitarian",
      tabAssociations: ["flood-impacts", "higher-ground", "displacement"],
      visible: true,
      data: IDP_DATA,
      color: "#00FF00",
    },
    {
      id: "unmiss-civil-affairs",
      name: "UNMISS Civil Affairs",
      type: "geojson",
      category: "Humanitarian",
      group: "Humanitarian",
      tabAssociations: ["conflict-risk", "flood-impacts"],
      visible: true,
      data: UNMISS_CIVIL_AFFIARS,
      color: "#00FF00",
    },
    {
      id: "unmiss-patrol",
      name: "UNMISS Patrol",
      type: "geojson",
      category: "Humanitarian",
      group: "Humanitarian",
      tabAssociations: ["conflict-risk", "flood-impacts"],
      visible: true,
      data: UNMISS_PATROL,
      color: "#00FF00",
    },
    { id: "unmiss",
      name: "UNMISS",
      type: "geojson",
      category: "Humanitarian",
      group: "Humanitarian",
      tabAssociations: [],
      visible: true,
      data: UNMISS,
      color: "#00FF00",
    },
    {
      id: "floodwatch",
      name: "Floodwatch",
      type: "raster",
      category: "Coming Soon",
      group: "Coming Soon",
      disabled: true,
      tabAssociations: ["flood-impacts", "reports"],
      visible: false,
      tileUrl: floodwatchTileUrls["2026-07-15"], // Default tile URL
      tileUrlsByDate: floodwatchTileUrls,
      color: "#03a9f4",
      opacity: 0.7,
      minZoom: 0,
      maxZoom: 13,
    },
    {
      id: "flood",
      name: "Flood",
      type: "geojson",
      category: "Environmental",
      group: "Environmental",
      tabAssociations: ["flood-impacts", "reports"],
      visible: false,
      data: FloodPolygon,
      color: "#00FF00",
    },
    {
      id: "populated_places",
      name: "Populated Places",
      type: "geojson",
      category: "Humanitarian",
      group: "Humanitarian",
      tabAssociations: ["conflict-risk", "higher-ground", "displacement"],
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "infrastructure_damage",
      name: "Infrastructure Damage",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      disabled: true,
      tabAssociations: ["flood-impacts", "reports"],
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "crop_flooding",
      name: "Crop Flooding (FAO)",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      disabled: true,
      tabAssociations: ["flood-impacts", "reports"],
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "lake_victoria_flood_alert",
      name: "Lake Victoria Flood Alert",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["flood-impacts", "drought-stress"],
      disabled: true,
      visible: false,
      data: [],
      color: "#4fc3f7",
      opacity: 0.6,
    },
    {
      id: "chirps_rainfall",
      name: "CHIRPS Rainfall",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["flood-impacts", "drought-stress"],
      disabled: true,
      visible: false,
      data: [],
      color: "#4fc3f7",
      opacity: 0.6,
    },
    {
      id: "drought_index",
      name: "Drought Index (ASIS)",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "vegetation_health",
      name: "Vegetation Health",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "historic_agricultural_drought_frequency",
      name: "Historic Agricultural Drought Frequency",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "modis_ndvi",
      name: "MODIS NDVI",
      type: "raster",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "srtm_elevation",
      name: "SRTM Elevation",
      type: "raster",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "displacement_alerts",
      name: "Displacement Alerts",
      type: "raster",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "floodscan_historical_date",
      name: "Floodscan Historical Date",
      type: "raster",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "transhumance_patterns_kobo",
      name: "Transhumance Patterns",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "incomplete_harvest_kobo",
      name: "Incomplete Harvest",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "crop_conflict_and_water",
      name: "Crop Conflict and Water",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "crop_failure_kobo",
      name: "Crop Failure",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "survey_heatmap_kobo",
      name: "Survey Heatmap",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "patrol_geofenced_flood_zones_kobo",
      name: "Patrol Geofenced Flood Zones",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "vci_vhi_monthly_realtime",
      name: "VCI/VHI Monthly & Realtime",
      type: "raster",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "asap_crop_reports",
      name: "ASAP Crop Reports",
      type: "geojson",
      category: "Coming Soon",
      group: "Coming Soon",
      tabAssociations: ["drought-stress", "reports"],
      disabled: true,
      visible: false,
      data: [],
      color: "#00FF00",
    },
    {
      id: "topographic",
      name: "Topographic",
      type: "raster",
      category: "Geographic",
      group: "Geographic",
      tabAssociations: ["higher-ground"],
      visible: false,
      tileUrl: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
      data: [],
      color: "#00FF00",
    }
  ]

    // {
    //   id: "current-precipitation",
    //   name: "Current Precipitation",
    //   type: "raster",
    //   category: "flood-impacts",
    //   group: "Weather",
    //   tabAssociations: ["flood-impacts", "drought-stress"],
    //   visible: false,
    //   tileUrl: precipitationTileUrls["2026-06-15"], // Default tile URL
    //   tileUrlsByDate: precipitationTileUrls,
    //   color: "#4fc3f7",
    //   opacity: 0.6,
    //   minZoom: 0,
    //   maxZoom: 13,
    // },
    // {
    //   id: "drought-severity-index",
    //   name: "Drought Severity Index",
    //   type: "raster",
    //   category: "Environmental",
    //   group: "Environmental",
    //   tabAssociations: ["drought-stress", "reports"],
    //   visible: false,
    //   tileUrl: droughtTileUrls["2026-08-15"], // Default tile URL
    //   tileUrlsByDate: droughtTileUrls,
    //   color: "#ff9800",
    //   opacity: 0.8,
    //   minZoom: 0,
    //   maxZoom: 13,
    // },

}

